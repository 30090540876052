.TopNav {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  text-align: right;
  padding-top: .25rem;
  /* justify-content: flex-end; */
}
@media screen and (min-width: 500px) {
  .TopNav {
    flex-direction: row;
  }
}

.TopNav > * {
  padding: 1rem;
}