.projects-wrapper {
  width: 100%;
  background-color: #efefef;
  padding-top: 1rem;
}

.container {
  padding: .5rem;
}
@media (min-width: 640px) {
  .container {
    padding: 2rem;
  }
}

.hero {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
@media screen and (min-width: 450px) {
.hero > .text-box {
  width: 70%;
}
}

.bio-box {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 1rem;
background-color: orange;
}
@media 

.bio-box > h2 {
}

.autoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: 450px;
  gap: 2rem; 
  padding: 4rem 1rem; 
}

.cardContainer {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: relative;
  /* background: rgb(50,34,195);
  background: linear-gradient(0deg, rgba(50,34,195,0.6922969871542367) 0%, rgba(253,72,45,1) 100%); */
}

/* .cardContainer {
  border: solid 1px #f7f7f7;
  border-radius: 0.5rem;
  padding: 1.5rem;
  min-height: 100%;
}

@media (min-width: 30em) and (max-width: 80em) {
  .cardContainer {
    background-color: purple;
  }
} */

.card {
  min-width: 0;
  padding: .25rem;
  border-radius: 0.5rem;
}

.cardImage {
  /* border: rgb(129, 129, 129) solid 0.5px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
  background-size:cover;
  background-repeat:none;
}

.cardLinks {
  text-align: center;
}

.cardHeadline {
  line-height: 1rem;
  font-size: 1.25rem;
  color: black;
  margin: 1rem 0;
  padding-bottom: 2rem;
}

.caption {
  max-height: 20px;
}

.container {
  padding: 0;
}

.label {
  display: inline;
  position: absolute;
  top: -20px;
  left: 0px;
  text-align: left;
  background-color: rgb(237, 68, 68);
  color: white;
  height: 2rem;
  padding: .25rem;
}

.tag {
  display: flex;
  flex-direction: row;
  padding: 0 .5rem;
  border: 1px grey solid;
  margin-right: .5rem;
}

.projectContainer {
  display: block;
  padding: 1rem 0;
  width: 100%;
}
@media (min-width: 800px) {
  .projectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  } 
}

.contactWrap {
  display: flex;
}

.contactContainer {
  height: 100vh;
}

.panelHalf {
  width: 100%;
  flex-direction: column;
}
@media (min-width: 401px) {
  .panelHalf {
    width: 50%;
  }
}