/* * { border: 2px solid red; } */

*,
::before,
::after {
box-sizing: border-box;
}

html, body {
  width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  /* background-color: #efefef; */
  background-color: #efefef;
}

main {
  padding: 1em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, li {
  list-style-type: none;
}

img {
  max-inline-size: 100%;
  max-width: 100%;
  block-size: auto;
}

h1 {
  font-size: 3rem;
  font-weight: normal;
}
 
h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.6rem;
  line-height: 1.75rem;
}

h5, h6 {
  font-size: 1.2rem;
}

p {
  line-height: 1.6rem;
  font-size: 1.25rem;
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  /* background: rgb(50,34,195);
  background: linear-gradient(0deg, rgba(50,34,195,0.6922969871542367) 0%, rgba(183,45,253,1) 100%);  */
  background: #513dff;
  color: white;
  border-radius: 24px;
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  transition: 0.7s ease-in-out;
}

.btn:hover {
  background:#700ff8; 
  color: white;
}

a {
  text-decoration: none;
  font-size: 1.25rem;
  color: black;
  position: relative;
}

a > h4 {
  color: black;
}

p > a {
  color: rgb(0, 42, 255);
}

a:hover > h4 {
  text-decoration: underline;
}

blockquote {
  max-width: 750px;
  font-size: 1.25rem;
  line-height: 2rem;
}

.short-caption {
  max-width: 30ch;
  padding: .5rem;
}

header {
  padding: 0 .5rem;
}

/* CONTAINERS */

.split-container {
  padding: 1rem;
  max-width: 600px; 
}

.card-bordered {
  display: flex;
  border: 1px solid black;
  padding: .5rem 1rem;
}

.thumbnail {
  padding: .5rem 0;
  width: 100px;
}


  

/** GLOBAL LAYOUTS */


.glitch {
  position: relative;
  color: #fff;
  font-size: 80px;
}

.line:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}
.line:nth-child(1) {
  -webkit-animation: clip 3000ms -300ms linear infinite, glitch1 500ms -710ms linear;
          animation: clip 3000ms -300ms linear infinite, glitch1 500ms -710ms linear;
}
@-webkit-keyframes glitch1 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-2px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(-1px);
    color: #AC1212;
  }
  95% {
    transform: translateX(4px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch1 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-2px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(-1px);
    color: #AC1212;
  }
  95% {
    transform: translateX(4px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(2) {
  -webkit-animation: clip 3000ms -600ms linear infinite, glitch2 500ms -324ms linear;
          animation: clip 3000ms -600ms linear infinite, glitch2 500ms -324ms linear;
}
@-webkit-keyframes glitch2 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-3px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(3px);
    color: #AC1212;
  }
  95% {
    transform: translateX(1px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch2 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-3px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(3px);
    color: #AC1212;
  }
  95% {
    transform: translateX(1px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(3) {
  -webkit-animation: clip 3000ms -900ms linear infinite, glitch3 500ms -455ms linear;
          animation: clip 3000ms -900ms linear infinite, glitch3 500ms -455ms linear;
}
@-webkit-keyframes glitch3 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-1px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(0px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-1px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch3 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-1px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(0px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-1px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(4) {
  -webkit-animation: clip 3000ms -1200ms linear infinite, glitch4 500ms -291ms linear;
          animation: clip 3000ms -1200ms linear infinite, glitch4 500ms -291ms linear;
}
@-webkit-keyframes glitch4 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(1px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(4px);
    color: #AC1212;
  }
  95% {
    transform: translateX(2px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch4 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(1px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(4px);
    color: #AC1212;
  }
  95% {
    transform: translateX(2px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(5) {
  -webkit-animation: clip 3000ms -1500ms linear infinite, glitch5 500ms -13ms linear;
          animation: clip 3000ms -1500ms linear infinite, glitch5 500ms -13ms linear;
}
@-webkit-keyframes glitch5 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-1px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(3px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-4px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch5 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-1px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(3px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-4px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(6) {
  -webkit-animation: clip 3000ms -1800ms linear infinite, glitch6 500ms -709ms linear;
          animation: clip 3000ms -1800ms linear infinite, glitch6 500ms -709ms linear;
}
@-webkit-keyframes glitch6 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-2px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(2px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-4px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch6 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-2px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(2px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-4px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(7) {
  -webkit-animation: clip 3000ms -2100ms linear infinite, glitch7 500ms -314ms linear;
          animation: clip 3000ms -2100ms linear infinite, glitch7 500ms -314ms linear infinite;
}
@-webkit-keyframes glitch7 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(5px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(-2px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-1px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch7 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(5px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(-2px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-1px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(8) {
  -webkit-animation: clip 3000ms -2400ms linear infinite, glitch8 500ms -867ms linear;
          animation: clip 3000ms -2400ms linear infinite, glitch8 500ms -867ms linear;
}
@-webkit-keyframes glitch8 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(5px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(1px);
    color: #AC1212;
  }
  95% {
    transform: translateX(2px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch8 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(5px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(1px);
    color: #AC1212;
  }
  95% {
    transform: translateX(2px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(9) {
  -webkit-animation: clip 3000ms -2700ms linear infinite, glitch9 500ms -484ms linear;
          animation: clip 3000ms -2700ms linear infinite, glitch9 500ms -484ms linear;
}
@-webkit-keyframes glitch9 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-3px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(-1px);
    color: #AC1212;
  }
  95% {
    transform: translateX(0px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch9 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-3px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(-1px);
    color: #AC1212;
  }
  95% {
    transform: translateX(0px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
.line:nth-child(10) {
  -webkit-animation: clip 3000ms -3000ms linear infinite, glitch10 500ms -388ms linear;
          animation: clip 3000ms -3000ms linear infinite, glitch10 500ms -388ms linear infinite;
}
@-webkit-keyframes glitch10 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-3px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(-1px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-3px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes glitch10 {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
    color: #fff;
  }
  85% {
    transform: translateX(-3px);
    color: #4E9A26;
  }
  90% {
    transform: translateX(-1px);
    color: #AC1212;
  }
  95% {
    transform: translateX(-3px);
    color: #fff;
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes clip {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
            clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
  }
  100% {
    -webkit-clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
            clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
  }
}

@keyframes clip {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
            clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
  }
  100% {
    -webkit-clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
            clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
  }
}