.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
}

.container {
  padding: 1rem;
  background-color: yellow;
}