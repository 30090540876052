.auto-grid {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 0rem;
}


.bg-dark {
  background-color: #1c1c1c;
  color: #efefef;

}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;    
  /* gap: 20px; */
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-c {
  display: flex;
  flex-direction: column;
}

.flex-m {
  display:flex;
}
@media screen and (max-width: 400px) {
  .flex-m {
    flex-direction: column;
    padding: 0;
  }
}

.inline-flex {
  display: inline-flex;
}

.inner {
  max-width: 750px;
}

.paddingLg {
  padding: 2rem;
}

.text-col {
  max-width: 750px;
}

.arrow {
  font-size: 1.25rem;
  font-weight: normal;
}

.system {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
}

.gradient {
  background: -webkit-linear-gradient(45deg, rgb(122, 201, 227), rgb(199, 203, 219) ,rgb(100, 123, 208));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.third {
  width: 33%;
}

.xl {
  font-size: 2rem;
  line-height: 1.25rem;
}

.xxl {
  font-size: 4em;
}

.bold {
  font-weight: bold;
}

.upper {
  text-transform: uppercase;
}

.tightHeight {
  line-height: .5rem;
}

.lh-room > p {
  line-height: 1.75;
}


