.wrapper {
  max-width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "header"
    "sideNav"
    "main"
    "sidebar";
}
@media (min-width: 500px) {
  .wrapper {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "header  header header header"
      "sideNav main main main"
      "sideNav main main main"
  }
}
  /* @media (min-width: 700px) {
    .wrapper {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        "header header  header header header header"
        "sideNav main main main main main"
        "sideNav main main main main main";
     }
  } */
  
.header {
  grid-area: header;
  width: 100%;
  padding: 1rem 0;
}

.sideNav {
  grid-area: sideNav;
  padding: 1.5rem;
}

main {
  background: white;
  padding: 12px;
}

.projectContainer {
  min-height: 100$;
  grid-area: main;
}

.sidebar {
  grid-area: sidebar;

}

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
}
@media screen and (min-width: 400px) {
  .container {
    padding: 1rem;
  }
}

.urlList {
  display: flex;
  flex-wrap: wrap;
  gap: .75rem;
  /* flex-direction: column; */
  font-size: 1.5rem;
  
}

.card {
  display: flex;
  border: 1px solid black;
  padding: .5rem 1rem;
  width: 350px;;

}


.urlList > a {
  min-height: 100%;
  color: white;
  font-size: 1.25rem;
  padding: 1rem .5rem;
  margin-right: 1rem;
  margin-bottom: 2em;;
  background-color: rgb(252, 62, 62);
}



@media screen and (min-width: 400px) {
  .summary {
    padding: 1.5rem 0;
  }
}

.textCol {
  font-size: 1.25rem;
  max-width: 750px;
  line-height: 2.0rem; 
}

.excerptContainer {
  background-color: #f1f1f1;
  max-width: 750px;
}

a.caption {
  color: black;
  font-size: 1.25rem;
  padding-top: 1rem;
}